<template>
  <main class="dgrid-body">
    <h2 class="view-title">Open Data</h2>
    <p class="text--subtitle view-subtitle"></p>
  </main>
</template>

<script>

export default {
  name: 'OpenData'
}
</script>
